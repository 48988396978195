<template>
    <div>
        

        <div class="columns">
            <div class="column">
                <div class="is-pulled-right ml-4">
                    <input type="text" class="input" v-model="search" placeholder="Search ..."/>
                </div>
                <div class="is-pulled-right ml-4">
                    <select class="input" v-model="grade_id" @change="reloadBookings()">
                        <option value="">Select Grade ...</option>
                        <option :value="row.id" v-for="row in grades" :key="row.id">{{ row.name }}</option>
                    </select>
                </div>
                <div class="is-pulled-right ">
                   
                    <button class="button is-danger " v-if="new_bookings.length > 0 && 1==2" @click="getNewBookings">New Bookings Found - Click to Reload</button>
                    <button class="button " :class="{'is-link' : hide_missed == 1}" @click="updatedHideMissed()">Hide Missed Bookings</button>
                    
                    
                   
        
                </div>
                
                <h3 class="title">New Bookings</h3>

                <div class="notification is-warning" v-if="loading == true">
                    Loading bookings ...
                </div>
                <div class="notification is-danger" v-if="loading == false  && filtered_bookings.length == 0">
                    No bookings can be found matching your search criteria
                </div>

                <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading == false && filtered_bookings.length > 0">
                    <thead>
                        <tr>
                            <th width="20">ID</th>
                            
                            <th>Client</th>
                            <th>Ward</th>
                            <th>Referece</th>
                            <th>Grade</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>User</th>
                            <th width="40">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in filtered_bookings" :key="row.id" >
                            <td><a :href="'/bookings/' + row.id" target="_blank">{{ row.id }}</a></td>
                           
                            <td>{{ row.client_name }} <span class="tag is-warning" v-if="row.sleeping_nurse==1">Sleeping Nurse</span></td>
                            <td>{{ row.ward_name }}</td> 
                            <td>{{ row.reference }}</td>
                            <td>
                                <span class="has-text-link" v-if="row.gender_id==2"> {{ row.grade_code }}</span>
                                <span class="has-text-danger" v-else-if="row.gender_id==1"> {{ row.grade_code }}</span>
                                <span class="" v-else> {{ row.grade_code }}</span>
                                <span class="tag is-danger" v-if="checkBookingGrade(row.client_type_id, row.grade_id) == true">Check Grade</span>
                            </td>
                            <td>{{ row.start_date_formatted }}</td>
                            <td>{{ row.start_time }} - {{ row.end_time }}</td>
                            <td>{{ row.user_forename }}</td>
                            <td>
                                <span  class=" tag button is-small is-danger" v-if="row.end_date_time <= row.start_date_time" title="End date is same or before start date">Problem</span>
                                <span  class=" tag button is-small is-success" v-if="row.end_date_time > row.start_date_time" @click="actionBooking(row.id)">Action</span>
                            </td>
                        </tr>
                    
                    </tbody>

                </table>
            </div>
        </div>

        <div class="columns" id="pending">

            <div class="column">
                <h3 class="title">Pending Bookings</h3>

                <div class="notification is-warning" v-if="loading_pending == true">
                    Loading bookings ...
                </div>
                <div class="notification is-danger" v-if="loading_pending == false  && filtered_pending_bookings.length == 0">
                    No bookings can be found matching your search criteria
                </div>

                <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading_pending == false && filtered_pending_bookings.length > 0">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th width="20">Replies</th>
                           
                            <th>Client</th>
                            <th>Ward</th>
                            <th>Reference</th>
                            <th>Grade</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>User</th>
                            <th width="40">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in filtered_pending_bookings" :key="row.id" :class="{'has-background-warning' : row.interested_member_alerts.length > 0}">
                            <td><a :href="'/bookings/' + row.id" target="_blank">{{ row.id }}</a></td>
                            <td>{{ row.interested_member_alerts.length }}</td>
                            
                            <td>{{ row.client_name }} <span class="tag is-warning" v-if="row.sleeping_nurse==1">Sleeping Nurse</span></td>
                            <td>{{ row.ward_name }}</td>
                            <td>{{ row.reference }}</td>
                            <td>
                                <span class="has-text-link" v-if="row.gender_id==2"> {{ row.grade_code }}</span>
                                <span class="has-text-danger" v-else-if="row.gender_id==1"> {{ row.grade_code }}</span>
                                <span class="" v-else> {{ row.grade_code }}</span>
                                <span class="tag is-danger" v-if="checkBookingGrade(row.client_type_id, row.grade_id) == true">Check Grade</span>
                            </td>
                            <td>{{ row.start_date_formatted }}</td>
                            <td>{{ row.start_time }} - {{ row.end_time }}</td>
                            <td>{{ row.user_forename }}</td>
                            <td>
                                <span  class=" tag button is-small is-danger" v-if="row.end_date_time <= row.start_date_time" title="End date is same or before start date">Problem</span>
                                <span  class=" tag button is-small is-success" v-if="row.end_date_time > row.start_date_time" @click="actionBooking(row.id)">Action</span>
                            </td>
                        </tr>
                    
                    </tbody>

                </table>
            </div>
        </div>

        <ActionBookingModal :booking_id="selected_booking_id" :showModal="showModal" @closeModal="closeModal" @reloadBookings="reloadBookings"/>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

import ActionBookingModal from '@/components/Bookings/ActionBookingModal.vue';

export default {
    
      name: 'NewBookings',
        mixins: [MemberMixin],
        components: {
            ActionBookingModal
        },
        data: function () {
            return {
                   search: '',
                   bookings: [],
                   pending_bookings: [],
                   loading: true,
                   loading_pending: true,
                   searchPerformed: false,
                   showModal: false,
                   assigned:false,
                   alertText: '',
                   members: [],
                   selected_booking_id: '',
                   working:false,
                   hide_missed: 1,
                   new_bookings: [],
                   grades: [],
                   grade_id : ''
            }
        },
        methods: {
            async reloadBookings() {
                this.getNewBookings();
                this.getPendingBookings();
            },
            updatedHideMissed() {
                if(this.hide_missed== 1)
                {
                    this.hide_missed = 0
                }
                else
                {
                    this.hide_missed = 1
                }
                this.getNewBookings()
            },  
            async getNewBookings() {
                this.loading=true
                this.new_bookings = []
                
                UtilityService.getSimpleApiData(this.accessToken, "bookings/new?hide_missed=" + this.hide_missed + "&grade_id=" + this.grade_id)
                .then(
                    (bookings => {
                        this.$set(this, "bookings", bookings);
                        this.loading = false
                    }).bind(this)
                );
            },
            async getPendingBookings() {
                this.loading_pending=true
                
                UtilityService.getSimpleApiData(this.accessToken, "bookings/pending" + "?grade_id=" + this.grade_id)
                .then(
                    (bookings => {
                        this.$set(this, "pending_bookings", bookings);
                        this.loading_pending = false
                    }).bind(this)
                );
            },
            async actionBooking(booking_id) {
                this.selected_booking_id = booking_id
                this.showModal = true
                //this.getMembers();

                
            },
            async checkForNewBookings() {
                
                UtilityService.getSimpleApiData(this.accessToken, "bookings/new?hide_missed=" + this.hide_missed)
                .then(
                    (new_bookings => {
                        this.$set(this, "new_bookings", new_bookings);
                       
                    }).bind(this)
                );
            },
            
            
            
        },
        computed : {
            filteredMembers: function () {
            
                let filteredMembers =  this.members.filter(function(u) { 
                    if(  u.checked == 1) 
                    {
                    return 1
                    }
                })
                return filteredMembers
            },
            
            filtered_bookings: function () {
            
                let filtered_bookings =  this.bookings

                if(this.search != '' && this.search != null)
                {
                    filtered_bookings = filtered_bookings.filter(
                        row => (row.id != null && row.id.toString().toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.reference != null && row.reference.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.client_name != null && row.client_name.toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }
                return filtered_bookings
            },
            filtered_pending_bookings: function () {
            
                let filtered_pending_bookings =  this.pending_bookings

                if(this.search != '' && this.search != null)
                {
                    filtered_pending_bookings = filtered_pending_bookings.filter(
                        row => (row.id != null && row.id.toString().toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.reference != null && row.reference.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.forename != null && row.forename.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() ) )
                        || (row.client_name != null && row.client_name.toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }
                return filtered_pending_bookings
            },
    
            membersToAlert: function () {
                const membersToAlert = this.filteredMembers.map( (member) => member.id )

                return membersToAlert
            }
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getNewBookings();
                this.getPendingBookings();

                this.getGrades();

                //window.setInterval(() => {
                //this.checkForNewBookings()
                //}, 60000)
                
            });
        
        },
        
    }
</script>