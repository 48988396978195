

<template>
    <div class="modal " :class="{'is-active': showModal==true}">
        <div class="modal-background"></div>
        <!-- NEW bookings only-->
        <div class="modal-card" v-if="(booking.booking_status_id==1 || booking.booking_status_id==2) && this.composer_view == false" style="width:1040px;max-width:100%">
            <header class="modal-card-head">
                <p class="modal-card-title">Action Booking {{booking.id}} - {{ booking.grade_code }}

                    <button class="button is-small ml-3" :class="{'is-success' : this.showUnavailable == 1}" style="top:-3px" @click="toggleUnavailable">Include Unavailable</button>
                    <button class="button is-small ml-3" :class="{'is-success' : this.showWorking == 1}" style="top:-3px" @click="toggleWorking">Include Working</button>
                    
                </p>

                
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" >
                <p class="is-size-7 mb-2">Selecting Available only will match everyone with these times set on {{  this.booking.start_date_formatted }} : <span v-for="(item, index) in time_periods" :key="item.id"><span v-if="index > 0">, </span>{{ item.name }} </span></p>
                <div class="notification is-warning" v-if="loadingMembers == true">
                    Loading members ...
                </div>
                <div class="notification is-danger" v-if="loadingMembers == false  && filtered_members.length == 0">
                    There are not matching members for this booking
                </div>
                <table class="modaltable table is-fullwidth is-hoverable is-size-7" v-if="loadingMembers == false && filtered_members.length > 0">
                    <thead>
                        <tr>
                            <th width="10" ><input type="checkbox" v-model="check_all" @click.prevent="checkAllShowing()" ></th>
                            <th>Name</th>
                            <th>Grades</th>
                            <th>Avail</th>
                            <th>NMC</th>
                            <th>NISCC</th>
                            <th>Access NI</th>
                            <th>Interest</th>
                            <th>Previous</th>
                            <th style="display:none">Bookings</th>
                            <th width="50">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <AssignBookingRow v-for="row in filtered_members" :key="'member' + row.id" :member="row" :booking="booking" :user="user" :working="working" @assignBookingFromButton="assignBooking(row)" @checkBox="checkBox(row)"/>
                                
                        
                    </tbody>

                </table>

                <p>Or search for members</p>
                <form @submit.prevent="searchMembers()">
                    <div class="field">
                        <div class="control">
                            <input class="input" type="text" v-model="search"  >
                        </div>
                    </div>
                </form>

                
            </section>
            
            <footer class="modal-card-foot " style="display:block">
                <div class="buttons " style="display:block">
            <button class="button is-success" @click="composeAlert()" :disabled="this.working==true || this.membersToAlert.length == 0">Compose Alert ({{ this.membersToAlert.length }})</button>
            <button class="button" @click="closeModal()" :disabled="this.working==true">Cancel</button>

            <button class="button is-danger is-pulled-right "  @click="setAsMissed()" :disabled="this.working==true" >Set as Missed</button>
        </div>
            </footer>
        </div>
        <!-- end of new bookings-->

        <!-- Compose the alert -->
        <div class="modal-card" v-if="this.loading == false && this.composer_view == true" style="width:840px">
            <header class="modal-card-head">
                <p class="modal-card-title">Compose Alert</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" >
                <div class="field">
                    <label class="label">Alert Text</label>
                    <div class="control">
                        <textarea class="textarea" type="text" v-model="booking.sms_text" required  @input="countCharacters"></textarea>
                        <p><em>Character Count: {{ characterCount }}</em></p>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Alert Type</label>
                    <div class="control">
                        <input class="checkbox" type="checkbox" v-model="app_only"  />
                        <p><em>Check this box to send the alert as an App Notification ONLY</em></p>
                    </div>
                </div>

                <div class="field is-grouped" >
                    <div class="control">
                        <button class="button is-success" :disabled="working==true || characterCount == 0" @click="sendAlerts()">Send Alerts  ({{ this.membersToAlert.length }})</button>
                    </div>
                </div>
            </section>
        </div>
        
        <!-- if booking is already assigned -->
        <div class="modal-card" v-if="this.loading == false && (booking.booking_status_id==3 || booking.booking_status_id==4)" style="width:840px">
            <header class="modal-card-head">
                <p class="modal-card-title">Assign Booking</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body" >
                <div class="notification is-danger">This booking has already been assigned to a member</div>
            </section>
        </div>
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';
import AssignBookingRow from '@/components/Generic/AssignBookingRow.vue';

export default {
    
      name: 'NewBookings',
        mixins: [MemberMixin],
        components: {
            //MemberGradeTags,
            AssignBookingRow
        },
        props: ['booking_id', 'showModal'],
        data: function () {
            return {
                   search: '',
                   booking: [],
                   loading: true,
                   searchPerformed: false,
                   assigned:false,
                   members: [],
                   working:false,
                   loadingMembers: true,
                   showUnavailable: 0,
                   showWorking: 0,
                   user: [{admin:0}],
                   check_all: false,
                   composer_view : false,
                   characterCount: 0,
                   time_periods: [],
                   app_only: false
            }
        },
        methods: {
            countCharacters() {
                this.characterCount = this.booking.sms_text.length;
            },
            checkBox(row)
            {
                row.checked = true
            },
            composeAlert() {
                this.composer_view = true
            },  
            checkAllShowing() {
                for (let key in this.filtered_members) {
                    if(this.filtered_members[key].blocked == 0 && this.filtered_members[key].excluded==0 && this.filtered_members[key].already_working==0 
                        && this.filtered_members[key].invalid==0 && this.filtered_members[key].training_blocked==false)
                    {
                        this.filtered_members[key].checked = true;
                    }
                    
                }

                this.check_all = false
            },
            toggleUnavailable()
            {
                if(this.showUnavailable == 1)
                {
                    this.showUnavailable = 0
                }
                else {
                    this.showUnavailable = 1
                }
            },
            toggleWorking()
            {
                if(this.showWorking == 1)
                {
                    this.showWorking = 0
                }
                else {
                    this.showWorking = 1
                }
            },
            async getBooking() {
                this.loading=true
                
                
                UtilityService.getSimpleApiData(this.accessToken, "bookings/" + this.booking_id)
                .then(
                    (booking => {
                        this.$set(this, "booking", booking);
                        this.loading = false
                        this.countCharacters()
                    }).bind(this)
                );
            },
            async getMembersForBooking() {
                this.loadingMembers=true
                
                
                UtilityService.getSimpleApiData(this.accessToken, "bookings/" + this.booking_id + "/members")
                .then(
                    (members => {
                        this.$set(this, "members", members);
                        this.loadingMembers = false
                    }).bind(this)
                );
            },
            async searchMembers() {
                this.loadingMembers=true
                this.members = []
                
                UtilityService.getSimpleApiData(this.accessToken, "members?search=" + this.search + "&booking_id=" + this.booking_id)
                .then(
                    (members => {
                        this.$set(this, "members", members);
                        this.loadingMembers = false
                    }).bind(this)
                );
            },
           
            async assignBooking(member) {
                this.working = true

                if(confirm('Are you sure you want to assign this booking to the selected member ?'))
                {
                    var formdata = { 
                        _method: 'patch',
                        member_id : member.id,
                    }; 

                    BookingService.updateBooking(this.accessToken, this.booking.id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            this.closeModal()

                            this.$emit('reloadBookings')

                        }
                    );
                }
                else{
                    this.working=false;
                }

                
            },
            async setAsMissed() {
                this.working = true
                if(confirm('Are you sure you want to mark this booking as Missed ?'))
                {
                    var formdata = { 
                        _method: 'patch',
                        booking_status_id: 5
                    };
                    BookingService.updateBooking(this.accessToken, this.booking.id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            this.closeModal()

                            this.$emit('reloadBookings')

                        }
                    );
                }
                else{
                    this.working=false;
                }
            },
            async sendAlerts() {
                this.working = true
                
                var formdata = { 
                    members : this.membersToAlert,
                    text: this.booking.sms_text,
                    app_only: this.app_only
                }; 

                BookingService.sendAlerts(this.accessToken, this.booking.id, formdata)
                .then(
                    () => {
                        
                        this.closeModal();
                        this.working=false;
                        this.composer_view = false;

                        this.$emit('reloadBookings')

                    }
                );
                
            },
            async closeModal() {
                this.check_all = false
                this.$emit('closeModal')
            },
            
        },
        computed : {
            available_members: function () {
                if(this.showUnavailable == 0)
                {
                    let available_members =  this.members.filter(function(u) { 
                        if(  u.available == 1) 
                        {
                            return 1
                            
                        }
                    })

                    return available_members
                }
                else{
                    return this.members
                } 
                
               
            },
            working_members: function () {
                if(this.showWorking == 0)
                {
                    let working_members =  this.available_members.filter(function(u) { 
                        if(  u.already_working == 0) 
                        {
                            return 1
                            
                        }
                    })

                    return working_members
                }
                else{
                    return this.available_members
                } 
            },
            filtered_members: function () {
                let filtered_members =  this.working_members.filter(function(u) { 
                    if(  u.cleared == 1) 
                    {
                        return 1
                        
                    }
                })
                return filtered_members
            },
                
            checkedMembers: function () {
            
                let checkedMembers =  this.filtered_members.filter(function(u) { 
                    if(  u.checked == 1) 
                    {
                    return 1
                    }
                })
                return checkedMembers
            },
            membersToAlert: function () {
                const membersToAlert = this.checkedMembers.map( (member) => member.id )

                return membersToAlert
            }
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getUserSelf()
            });
        
        },
        watch: {
            booking_id() {
                this.members = []
                this.time_periods = []
                //this.getBooking();
                //this.getMembersForBooking();
                
            },
            showModal() {
                this.members = []
                this.time_periods = []
                
                if(this.showModal == true)
                {
                    this.composer_view = false;
                    this.getBooking();
                    this.getMembersForBooking();
                    this.getTimePeriods(this.booking_id)
                }
                else{
                    this.search = ''
                }
            }
        },
    }
</script>